<template>
  <Navbar :user="user" />
    <div class="container pb-100 pt-100" v-if="!isLoading">
      <div class="section-title">
        <h2>How do you want your course title to be?</h2>
      </div>
      <div class="contact-form">
        <form id="contactForm" @submit.prevent="editCourse">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <div class="form-group text-start">
                  <label for="name" class="text-start">Title</label>
                <input type="text" v-model="form.title" id="name" class="form-control" required="" data-error="Please enter your name" placeholder="Your Name"/>
                <div v-if="errors.title" class="text-small text-danger text-start">{{ errors.title[0] }}</div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="form-group text-start">
                  <label for="name" class="text-start">Choose Category</label>
                  <select  v-model="form.course_category_id" id="" class="form-control" @change="getSubCategories()"  v-on:click="editable = !editable">
                      <option value="">Choose Category</option>
                      <option :value="category.id" v-for="category in categories" :key="category.id">{{ category.name }}</option>
                  </select>
                <div v-if="errors.course_category_id" class="text-small text-danger text-start">{{ errors.course_category_id[0] }}</div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6" v-if="form.course_category_id">
              <div class="form-group text-start">
                  <label for="name" class="text-start">Choose Sub Category</label>
                  <select  v-model="form.sub_course_category_id" id="" class="form-control" @change="getSubjects()">
                      <option value="">Choose Sub Category</option>
                      <option :value="sub.id" v-for="sub in sub_categories" :key="sub.id">{{ sub.name }}</option>
                  </select>
                <div v-if="errors.sub_course_category_id" class="text-small text-danger text-start">{{ errors.sub_course_category_id[0] }}</div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6" v-if="form.sub_course_category_id">
              <div class="form-group text-start">
                  <label for="name" class="text-start">Choose Subject</label>
                  <select v-model="form.subject_id" class="form-control" >
                      <option value="">Choose Subject</option>
                      <option :value="subject.id" v-for="subject in subjects" :key="subject.id">{{ subject.name }}</option>
                  </select>
                <div v-if="errors.subject_id" class="text-small text-danger text-start">{{ errors.subject_id[0] }}</div>
              </div>
            </div>
            <div class="col-lg-12 col-md-12">
              <button type="submit" class="btn default-btn" disabled v-if="submitting">
                <span class="label">{{ value }}</span>
              </button>
              <button type="submit" class="default-btn btn" style="pointer-events: all; cursor: pointer" v-else>
                <span class="label">Edit Course</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div v-else>
      <Loading />
    </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue"
import axios from 'axios'
import Loading from '@/components/Loading.vue';
export default {
  components: { Navbar,Loading },
  data(){
    return {
      isLoading: true,
      submitting: false,
      token: localStorage.getItem('user_data'),
      form:{
        title:'', course_category_id:'', sub_course_category_id:'', subject_id:'',
      },
      editableSubCat: false,
      user: {},
      categories: {},
      sub_categories: {},
      subjects: {},
      errors: {}
    }
  },
  methods:{
    editCourse(){
      this.submitting = true
      this.value = 'Please Wait...'
      let id = this.$route.params.id
      axios.post('https://apitraining.vipawaworks.com/api/course/update/' + id,this.form).then(response =>{
        response.data
        this.$router.push({ name: 'CourseDashboard', params: {id: id}})
      }).catch((errors) => {
        this.errors = errors.response.data.errors
        this.submitting = false
      })
    },
    getCourse(){
        let id = this.$route.params.id
        axios.get('https://apitraining.vipawaworks.com/api/course/profile/' + id).then(response => {
            this.form = response.data
        }).catch(errors => {
            this.errors = errors.response.data.errors
        })
    },
    getCategories(){
      axios.get('https://apitraining.vipawaworks.com/api/course/get_all_categories').then(response => {
        this.categories = response.data
      }).catch(errors => {
        this.errors = errors.response.data.errors
      })
    },
    getSubCategories(){
      axios.get('https://apitraining.vipawaworks.com/api/course/get_all_sub_categories/' + this.form.course_category_id).then(response => {
        this.sub_categories = response.data
      }).catch(errors => {
        this.errors = errors.response.data.errors
      })
    },
    getAllSubCategories(){
      axios.get('https://apitraining.vipawaworks.com/api/course/get_all_sub_categories').then(response => {
        this.sub_categories = response.data
      }).catch(errors => {
        this.errors = errors.response.data.errors
      })
    },
    getSubjects(){
      axios.get('https://apitraining.vipawaworks.com/api/course/get_all_subjects/' + this.form.sub_course_category_id).then(response => {
        this.subjects = response.data
      }).catch(errors => {
        this.errors = errors.response.data.errors
      })
    },
    getAllSubjects(){
      axios.get('https://apitraining.vipawaworks.com/api/course/get_all_subjects' ).then(response => {
        this.subjects = response.data
      }).catch(errors => {
        this.errors = errors.response.data.errors
      })
    },
    getUser(){
      axios.get('https://apitraining.vipawaworks.com/api/user').then(response => {
        this.user = response.data
      }).catch(errors => {
        if (errors.response.status === 401) {
          localStorage.removeItem('user_data')
          this.$router.push({ name: 'Login'})
        }
      }).finally(() => {
        setTimeout(function () {
            this.isLoading =  false
        }.bind(this), 500);
      })
    },
  },
  created(){
    document.title = `Create Course - Training`
    axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
    this.getUser() 
    this.getCategories() 
    this.getCourse()    
    this.getAllSubCategories() 
    this.getAllSubjects() 
  }
};
</script>

<style>
</style>